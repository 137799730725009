@media (max-width: 1680px) and (min-width: 1280px) {
  .inner {
    width: 1170px;
  }
  :root {
    --prodGap: 15px;
    --rowGap: 48px;
  }
  .top-bar .top-bar-texts {
    gap: 16px;
  }
  #search-engine {
    left: initial;
  }
  .header-tools-wrapper {
    gap: 18px;
  }
  .logo {
    position: relative;
    top: -8px;
  }
  #footer .map-wrapper {
    margin-left: 50px;
  }
  .detail-main-wrapper {
    grid-template-columns: 507px minmax(0, 1fr) minmax(0, 313px);
  }
  .detail-headline,
  .detail-additional-infos {
    margin-left: 0;
  }
  #detail-info {
    padding: 16px;
  }
  .detail-amount {
    width: 96px;
  }
  #detail-tabs nav button {
    font-size: 16px;
    padding: 0 32px;
  }
  .product-price del {
    top: 0;
  }
  .greeting .greeting-body {
    max-height: 82%;
  }
  .comments-list {
    gap: 32px 16px;
  }
  .under-comment {
    font-size: 15px;
    gap: 8px;
  }
  .under-comment .added-date {
    padding-left: 8px;
    margin-left: 0;
  }
}
@media (max-width: 1279px) {
  :root {
    --rowGap: 25px;
    --fontSize: 13px;
    --lineHeight: 1.4;
  }
  .cms-subarticles-wrapper {
    columns: 2 !important;
  }
  .user-orders-list .order-header span {
    padding-left: 24px;
  }
  .user-orders-list .order-header span i {
    width: 16px;
    height: 16px;
  }
  .footer-bottom-bar {
    padding: 30px 0 100px;
  }
  .detail-omnibus {
    text-align: left;
  }
  main {
    padding-bottom: var(--rowGap) !important;
  }
  main.aside {
    grid-template-columns: minmax(0, 1fr);
    grid-gap: var(--rowGap) 0;
  }
  .inner {
    width: 100%;
    padding: 0 10px;
  }
  * {
    -webkit-tap-highlight-color: rgba(189, 195, 199, 0.75);
    -webkit-text-size-adjust: none;
  }
  .field-wrapper:not(.checking-reg) label {
    left: 10px;
  }
  body.privacy-box-visible {
    overflow: hidden;
  }
  body.privacy-box-visible #container {
    filter: blur(5px);
    pointer-events: none;
  }
  .privacy-box-trigger {
    bottom: 15px;
  }
  .privacy-box-trigger.right-side-cookies {
    right: 15px;
  }
  .privacy-box-trigger.left-side-cookies {
    left: 15px;
  }
  .privacy-box {
    bottom: auto;
    right: auto;
    left: 15px !important;
    right: 15px !important;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100vw;
    max-height: calc(100% - 40px);
  }
  input[type=text],
  input[type=password],
  input[type=email],
  select,
  textarea {
    font-size: 16px;
    padding: 0 14px;
  }
  textarea {
    padding: 10px 14px;
  }
  .field-wrapper:not(.checking-reg).icon input {
    padding-left: 40px;
  }
  .field-wrapper:not(.checking-reg).icon:before {
    width: 40px;
  }
  .field-wrapper:not(.checking-reg).icon label {
    left: 36px;
  }
  #facebook-box,
  .detail-loupe {
    display: none;
  }
  #detail-images .swiper-button-prev,
  #detail-images .swiper-button-next {
    height: 80px;
    margin-top: -40px;
  }
  #detail-images .swiper-button-prev {
    left: -45px;
  }
  #detail-images .swiper-button-next {
    right: -45px;
  }
  .faq-list > li > span {
    font-size: 15px;
  }
  .headline {
    font-size: 20px !important;
    margin-bottom: 10px;
  }
  #content {
    width: 100%;
    clear: both;
  }
  .sidebar {
    width: auto;
    display: contents;
  }
  .sidebar .sidebar-tabs {
    display: none;
  }
  #search-engine {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    z-index: 1001;
    padding: 80px 30px 30px;
    -webkit-overflow-scrolling: touch;
  }
  #search-engine.show-with-last-phrases .suggestion-result-products,
  #search-engine.show-with-last-phrases #suggestions .no-products-info {
    display: none !important;
  }
  #search-engine.show-with-last-phrases .suggestion-last-phrases {
    display: block !important;
  }
  #search-engine.no-products #suggestions .no-products-info {
    display: block;
  }
  #searchbutton {
    position: absolute;
    top: 81px;
    right: 33px;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1E1E1E;
    font-size: 20px;
    background-color: white;
  }
  #searchbutton:after {
    content: '\e80d';
    font-family: icomoon;
  }
  .search-engine-close {
    position: absolute;
    height: 60px;
    display: flex;
    align-items: center;
    color: #1E1E1E;
    top: 0;
    left: 0;
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 15px;
    cursor: pointer;
    user-select: none;
    gap: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #DFDFDF;
    padding: 0 30px;
  }
  .search-engine-close:before {
    content: '\e803';
    font-size: 12px;
    font-family: icomoon;
    font-weight: normal;
  }
  .show-search-engine #search-engine {
    display: block;
  }
  #suggestions {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    width: 100%;
    filter: none;
    border-radius: 0;
    padding: 0;
    margin-top: 10px;
    overflow-y: auto;
    flex-wrap: wrap;
    opacity: 1;
    visibility: visible;
  }
  #suggestions .suggestion-result-products {
    display: none;
  }
  #suggestions.show .suggestion-result-products:not(.hidden) {
    display: block;
  }
  .suggestion-last-phrases {
    width: 100%;
    order: -1;
    margin-bottom: 20px;
    display: none;
  }
  .suggestion-last-phrases span {
    font-size: 15px;
    padding: 3px 0;
  }
  .suggestion-left-column {
    display: none;
  }
  a.suggestions-advanced-search {
    margin-top: 0;
  }
  .suggestion-result-products {
    margin-bottom: 20px;
  }
  .newsletter-bg {
    background-image: none !important;
  }
  .newsletter-input {
    width: 100%;
  }
  .footer-content {
    flex-direction: column;
    padding-bottom: 20px;
  }
  .footer-item {
    width: 100% !important;
  }
  #pills-container:not(:empty) {
    gap: 10px;
    margin-bottom: 12px;
  }
  body:has(.gs-filtr-container) .filter-trigger {
    display: flex;
  }
  .filter-trigger {
    height: 46px;
    align-items: center;
    justify-content: space-between;
    font-weight: normal;
    font-size: 13px;
  }
  .filter-trigger svg {
    margin-left: 10px;
  }
  .single-pill {
    font-size: 12px;
  }
  .remove-all-filters {
    font-size: 12px;
  }
  #filter {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 1001;
    background-color: white;
    overflow-y: auto;
    padding: 60px 10px 120px;
  }
  #filter > h3 {
    display: none;
  }
  #filter .gs-filtr-container {
    border: 0;
    padding: 0 15px;
    border-bottom: 1px solid #DFDFDF !important;
  }
  #filter .gs-filtr-container:last-of-type {
    border-bottom: 0 !important;
  }
  #filter .gs-filtr-container.price-filtering {
    padding-bottom: 20px;
  }
  #filter .gs-filtr-container.price-filtering > strong:after {
    display: none;
  }
  #filter .gs-filtr-container.price-filtering label {
    line-height: 33px;
    font-size: var(--fontSize);
  }
  #filter .gs-filtr-container.colors ul {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }
  #filter .gs-filtr-container.colors li {
    display: block;
  }
  #filter .gs-filtr-container.colors label {
    font-size: 0;
    padding: 0;
    width: 34px;
    height: 34px;
    border: 2px solid #DFDFDF;
    outline: 4px solid white;
    outline-offset: -5px;
  }
  #filter .gs-filtr-container.colors label:before,
  #filter .gs-filtr-container.colors label:after {
    display: none;
  }
  #filter .gs-filtr-container.colors label:hover {
    border-color: #1E1E1E;
  }
  #filter .gs-filtr-container.colors input:checked + label {
    border-color: #1E1E1E;
    outline-width: 3px;
  }
  #filter .gs-filtr-container.show-list > strong:after {
    transform: translateY(-50%) scaleY(-1);
  }
  #filter .gs-filtr-container.show-list:not(.price-filtering) > div {
    display: block;
  }
  #filter .gs-filtr-container.show-list > input {
    display: block;
  }
  #filter .gs-filtr-container > strong {
    margin: 0;
    padding: 13px 0;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    color: #1E1E1E;
    font-size: 15px;
    cursor: pointer;
  }
  #filter .gs-filtr-container > strong.any-selected {
    color: #E3000F;
  }
  #filter .gs-filtr-container > strong:after {
    content: '\e800';
    font-family: icomoon;
    font-weight: normal;
    font-size: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
  #filter .gs-filtr-container > strong span {
    display: block;
    color: #4F4F4F;
    font-size: 12px;
    font-weight: normal;
  }
  #filter .gs-filtr-container > strong span:empty:before {
    content: 'brak wybranych';
  }
  #filter .gs-filtr-container > input {
    font-size: 16px;
    display: none;
    margin-bottom: 15px;
    height: 40px;
  }
  #filter .gs-filtr-container.price-filtering {
    padding-left: 10px;
    padding-right: 10px;
  }
  #filter .gs-filtr-container:not(.price-filtering) > div {
    display: none;
    padding-bottom: 10px;
  }
  #filter .gs-filtr-container:not(.price-filtering):not(.colors) > div {
    padding-left: 15px;
    margin-top: -5px;
  }
  #filter .gs-filtr-container li {
    display: block !important;
  }
  #filter .gs-filtr-container button {
    display: none;
  }
  #filter .gs-filtr-container input[type=checkbox] {
    display: none;
  }
  #filter .gs-filtr-container input[type=checkbox]:checked + label:after {
    transform: scale(1);
  }
  #filter .gs-filtr-container label {
    display: block;
    cursor: pointer;
    user-select: none;
    padding: 4px 0;
    padding-left: 34px;
    position: relative;
    color: #4F4F4F;
    font-size: 14px;
    line-height: 20px;
  }
  #filter .gs-filtr-container label.hide {
    display: none !important;
  }
  #filter .gs-filtr-container label:hover {
    text-decoration: underline;
  }
  #filter .gs-filtr-container label:before {
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    width: 20px;
    height: 20px;
    background-color: white;
    border: 1px solid #DFDFDF;
    transition: border-radius 0.1s ease-in-out;
  }
  #filter .gs-filtr-container label:after {
    content: '\e801';
    font-family: icomoon;
    position: absolute;
    top: 5px;
    left: 0;
    width: 20px;
    height: 20px;
    color: #1E1E1E;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.2s ease-in-out;
    transform: scale(0);
  }
  .close-filter {
    position: absolute;
    height: 60px;
    display: flex;
    align-items: center;
    color: #1E1E1E;
    background-color: #DFDFDF;
    top: 0;
    left: 0;
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 15px;
    cursor: pointer;
    user-select: none;
    gap: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 20px;
  }
  .close-filter:before {
    content: '\e803';
    font-size: 12px;
    font-family: icomoon;
    font-weight: normal;
  }
  #price-filter-amounts {
    gap: 10px;
  }
  #price-filter-amounts input {
    flex-grow: 1;
  }
  .close-filter-bottom {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-top: 1px solid #DFDFDF;
  }
  .close-filter-bottom > span {
    gap: 5px;
    font-size: 13px;
  }
  .close-filter-bottom > span[data-count]:after {
    content: attr(data-count);
  }
  .product-images-mini {
    width: 100%;
    height: auto;
    margin-top: 10px;
  }
  .product-images-mini .swiper-wrapper {
    transform: none !important;
    width: auto;
    justify-content: center;
  }
  .product-images-mini .swiper-slide {
    width: 10px;
    height: 10px;
    background-color: #DFDFDF;
    border-radius: 50%;
    margin: 0 5px;
  }
  .product-images-mini img {
    display: none !important;
  }
  .user-custom-content p {
    margin-bottom: 10px;
  }
  .comment-input-wrapper {
    margin-bottom: 12px;
  }
  .single-saved-cart .buttons-wrapper {
    height: auto;
  }
  .single-saved-cart .buttons-wrapper a,
  .single-saved-cart .buttons-wrapper i {
    padding: 3px 10px;
    font-size: 12px;
  }
  .cart-options-buttons img {
    height: 31px;
  }
  hr {
    margin: 20px 0;
  }
  .login-form.logged .logged-menu {
    flex-wrap: wrap;
  }
  .login-form.logged .logged-menu li {
    width: 100%;
    border-right: 0;
  }
  .login-form.logged .logged-menu li:not(:last-child) {
    border-bottom: 1px solid #DFDFDF;
  }
  .login-form.logged .logged-menu a svg {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%) !important;
    height: 50%;
    left: 20px;
  }
  .login-form.logged .logged-header h2 {
    padding-left: 0;
    overflow: hidden;
    max-width: 100%;
  }
  .login-form.logged .logged-header h2:before {
    display: none;
  }
  .login-form.logged .logged-header ul {
    width: 100%;
  }
  .login-form.logged .logged-header ul li {
    text-align: left;
  }
  .login-form.logged .logged-header ul a {
    gap: 10px;
  }
  .login-form.logged .logged-header ul a:before {
    order: 1;
    margin: 0;
    position: relative;
    top: -1px;
  }
  .overlay-visible {
    overflow: hidden;
    height: 100dvh;
    width: 100dvw;
    position: fixed;
    top: 0;
  }
  main:not(.aside) #tree {
    display: block;
  }
  .show-menu {
    overflow: hidden;
  }
  .show-menu #tree {
    transform: translate3d(0, 0, 0);
  }
  .show-menu .tree-canvas {
    opacity: 0.6;
    pointer-events: auto;
  }
  .tree-canvas {
    position: fixed;
    background: #000000 none repeat scroll 0% 0%;
    will-change: opacity;
    transition: opacity 300ms ease 0s;
    inset: 0px;
    z-index: 1001;
    opacity: 0;
    pointer-events: none;
  }
  #tree {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    height: 100dvh;
    z-index: 1002;
    background-color: #ececec;
    transform: translate3d(-100%, 0, 0);
    transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
    overflow: hidden;
    padding-top: 60px;
  }
  #tree ul:not(.cms-rwd) {
    background-color: white;
  }
  #tree nav {
    position: relative;
    min-width: 300px;
    width: auto;
    height: 100%;
    overflow-x: hidden;
    overflow-y: visible;
    scrollbar-width: none;
  }
  #tree nav > ul {
    position: relative;
  }
  #tree nav > ul.cms-rwd a {
    font-weight: normal;
  }
  #tree nav > ul:last-child {
    padding-bottom: 30px;
  }
  #tree nav > ul:not(:empty):before {
    display: block;
    padding: 10px 20px;
  }
  #tree nav > ul:nth-of-type(2):not(:empty) {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #ececec;
  }
  #tree nav > ul:nth-of-type(2):not(:empty):before {
    content: 'Menu';
  }
  #tree nav > ul:nth-of-type(2):not(:empty) a {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  #tree nav > ul:before {
    content: 'Kategorie';
    display: none;
  }
  #tree nav > ul.hide {
    left: -100%;
  }
  #tree nav > ul.hide .close-tree-header {
    display: none;
  }
  #tree nav > ul.hide + .cms-rwd {
    display: none;
  }
  #tree nav > ul ul {
    opacity: 0;
    pointer-events: none;
  }
  #tree nav > ul ul.active {
    left: 100%;
    top: 0;
    pointer-events: auto;
    opacity: 1;
  }
  #tree nav > ul ul.hide {
    left: 0;
  }
  #tree nav > ul ul.hide > .category-header {
    display: none;
  }
  #tree ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 10px;
  }
  #tree .parent > a {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }
  #tree .parent > a:after {
    content: "\e91a";
    margin-left: 20px;
    font-family: icomoon;
    line-height: 1;
    display: block;
    font-weight: normal;
    font-style: normal;
    text-transform: none;
    text-decoration: none;
    color: inherit;
    font-size: var(--iconSize);
    font-size: 18px;
    transform: rotate(-90deg);
  }
  #tree a:not(.tree-category-all-link) {
    padding: 15px 20px;
    color: #1E1E1E;
    display: block;
    font-weight: bold;
    font-size: 14px;
  }
  #tree a.tree-category-all-link {
    display: block;
    color: #1E1E1E;
    padding: 10px 20px;
    text-decoration: underline;
  }
  #tree a:not(.tree-category-all-link),
  #tree a.tree-category-all-link {
    /*&:hover,
            &:focus,
            &:active {
                background-color: @border-color;
            }*/
  }
  #tree .tree-header {
    position: fixed;
    height: 60px;
    display: flex;
    align-items: center;
    color: #1E1E1E;
    top: 0;
    left: 0;
    width: 100%;
    font-weight: bold;
    padding: 0 20px;
    text-transform: uppercase;
    font-size: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
    user-select: none;
    transform: translate3d(0, 0, 0);
  }
  #tree .tree-header:before {
    font-family: icomoon;
    font-weight: normal !important;
    font-size: 15px;
    display: flex;
    align-items: center;
  }
  #tree .tree-header.close-tree-header:before {
    content: '\e803';
    font-size: 12px;
    margin-right: 18px;
  }
  #tree .tree-header.category-header:before {
    content: "\e91a";
    transform: rotate(90deg) translateY(4px);
    font-family: icomoon;
    line-height: 1;
    display: block;
    font-weight: normal;
    font-style: normal;
    text-transform: none;
    text-decoration: none;
    color: inherit;
    font-size: var(--iconSize);
    margin-right: 10px;
  }
  #tree button,
  #tree small {
    display: none;
  }
  .shop-header {
    position: sticky;
    top: -100px;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out;
  }
  .shop-header.stuck {
    transform: none !important;
    /*            .header-bar {
                height: 0px;
                pointer-events: none;

                * {
                    opacity: 0;
                }
            }*/
  }
  #cart-box,
  #wishlist-box,
  .log-button,
  .search-engine-trigger,
  .tree-trigger {
    display: block;
    cursor: pointer;
    position: relative;
    padding-top: 25px;
  }
  #cart-box span,
  #wishlist-box span,
  .log-button span,
  .search-engine-trigger span,
  .tree-trigger span,
  #cart-box b,
  #wishlist-box b,
  .log-button b,
  .search-engine-trigger b,
  .tree-trigger b {
    display: none;
  }
  #cart-box:before,
  #wishlist-box:before,
  .log-button:before,
  .search-engine-trigger:before,
  .tree-trigger:before {
    font-family: icomoon;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 22px;
    line-height: 1;
    color: #1E1E1E;
  }
  #cart-box em,
  #wishlist-box em,
  .log-button em,
  .search-engine-trigger em,
  .tree-trigger em {
    color: #1E1E1E;
    font-style: normal;
    text-transform: uppercase;
    font-size: 9px;
    font-weight: 700;
    display: block;
    line-height: 14px;
  }
  #cart-box:before {
    content: "\e91b";
  }
  #cart-box i {
    font-size: 0;
    position: absolute;
    top: 1px;
    right: 5px;
    width: 8px;
    height: 8px;
    background-color: #E3000F;
    border-radius: 50%;
  }
  #wishlist-box:before {
    content: "\e90e";
  }
  #wishlist-box[data-wishlist-count="0"]:after {
    display: none;
  }
  #wishlist-box:after {
    content: '';
    position: absolute;
    top: 1px;
    right: 9px;
    width: 8px;
    height: 8px;
    background-color: #E3000F;
    border-radius: 50%;
  }
  .log-button:before {
    content: "\e90f";
  }
  .search-engine-trigger:before {
    content: "\e910";
  }
  .hamburger-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 25px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    justify-content: center;
  }
  .hamburger-icon span {
    display: block;
    width: 80%;
    height: 2px;
    border-radius: 1px;
    background-color: #1E1E1E;
  }
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    .header-tools-wrapper > * {
      margin-left: 10px;
    }
    .hamburger-icon {
      display: grid;
      grid-auto-rows: max-content;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 6px);
      gap: 0;
      justify-items: center;
      align-items: center;
    }
    .tree-canvas {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .mainmenu {
    display: none;
  }
  .main-banner-wrapper .decal {
    display: none !important;
  }
  body[data-type="mainpage"] main > div:nth-child(2),
  body[data-type="mainpage"] main > section:nth-child(2) {
    padding-top: 10px;
  }
  .top-bar {
    font-size: 12px;
  }
  .top-bar > * {
    padding-block: 4px;
  }
  .top-bar .top-bar-texts {
    gap: 20px;
  }
  .poland img {
    width: 18px;
  }
  .scrolling-producers {
    top: 4px;
  }
  .scrolling-producers:only-child {
    top: 0;
  }
  .header-bar {
    font-size: 12px;
  }
  .header-bar-left {
    gap: 16px;
  }
  .header-bar-left a {
    padding-left: 24px;
  }
  .header-bar-left a:before {
    font-size: 18px;
  }
  .header-bar-left a:first-of-type {
    margin-left: initial;
  }
  .header-tools-wrapper {
    gap: 24px;
  }
  .header-bar {
    height: 32px;
    padding-block: 0;
  }
  .header-bar-right {
    gap: 24px;
  }
  .logo img {
    width: auto;
    height: 64px;
  }
  .shop-header-container {
    height: 72px;
  }
  .header-tools-wrapper {
    position: relative;
    top: 7px;
  }
  .main-banner-wrapper {
    gap: 10px;
  }
  .main-banner .swiper-slide .banner-text,
  .side-banner .banner-text {
    font-size: 18px;
    bottom: 18px;
  }
  .slider1 {
    --swiper-navigation-sides-offset: -6px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 32px;
    height: 32px;
    margin-top: -16px;
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 16px;
  }
  .greeting {
    padding-left: 32px;
  }
  .decal {
    grid-template-columns: repeat(4, 14px);
    grid-template-rows: repeat(3, 14px);
  }
  .greeting-image-wrapper .decal {
    bottom: -36px;
    right: -36px;
  }
  .blog-banner b {
    font-size: 16px;
  }
  .blog-banner .red-button {
    margin-top: 10px;
  }
  .blog-banner:hover b {
    text-decoration: none;
  }
  .blog-banner:hover .red-button {
    color: #E3000F;
  }
  .blog-banner:hover .red-button:before {
    transform: none;
    background-color: #E3000F;
    height: 2px;
  }
  .producers-banner {
    --swiper-navigation-sides-offset: -6px;
    padding-top: var(--rowGap);
  }
  .blog-banners-wrapper {
    gap: 16px;
  }
  .footer-wrapper {
    flex-direction: column;
  }
  #footer .map-wrapper {
    margin-left: 0;
  }
  .footer-item.location .location-text {
    gap: 16px;
  }
  .footer-item {
    max-width: none;
  }
  .footer-bottom-bar {
    padding-block: 16px 82px;
  }
  .footer-item.location .location-text > div {
    padding-left: 28px;
  }
  .footer-item.location .location-text > div:before {
    font-size: 18px;
    top: 1px;
  }
  .product:after {
    display: none;
  }
  :root {
    --iconSize: 20px;
  }
  .product-price {
    gap: 8px;
  }
  .product-price b {
    font-size: 15px;
  }
  .product-price del {
    font-size: 13px;
    top: 1px;
  }
  .product-image {
    margin-bottom: 18px;
  }
  a.product-name {
    margin-bottom: 8px;
    font-size: 13px;
  }
  .product-add,
  .product-wishlist {
    width: 36px;
    height: 36px;
  }
  #breadcrumb {
    padding: 10px 0;
  }
  .category-header-wrapper {
    gap: 8px;
  }
  .red-button:hover {
    color: #E3000F;
  }
  .red-button:hover:before {
    transform: none;
    background-color: #E3000F;
    height: 2px;
  }
  .category-description-wrapper {
    padding-bottom: 28px;
  }
  .category-description-wrapper button {
    width: 100%;
  }
  .listing-wrapper {
    gap: 12px;
  }
  .listing-wrapper .products-list {
    padding-block: 3px;
  }
  .products-list,
  .products-list-tabs {
    grid-gap: 15px;
  }
  .pagination {
    margin-top: -15px;
  }
  .filter-trigger,
  .sorter-button {
    height: 40px;
    min-width: 280px;
  }
  .filter-trigger:after,
  .sorter-button:after {
    height: 40px;
  }
  .filter-trigger:after {
    font-size: 16px;
  }
  .navigation-wrapper {
    gap: 15px;
  }
  .detail-main-wrapper {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  #detail-info {
    position: static;
  }
  #detail-images {
    align-items: center;
    flex-direction: column;
    gap: 20px;
    height: auto;
    left: auto;
    top: auto;
    margin: 0 auto;
    position: relative;
    width: 100%;
  }
  #detail-images .swiper-button-prev {
    left: 0;
  }
  #detail-images .swiper-button-next {
    right: 0;
  }
  .detail-headline {
    margin: 0;
    text-align: center;
  }
  .detail-additional-infos {
    margin-left: 0;
  }
  #detail-info {
    padding: 20px;
    gap: 10px;
  }
  .detail-options {
    margin-top: 10px;
  }
  .detail-additional-infos {
    gap: 40px;
  }
  .detail-additional-infos > *:after {
    bottom: -20px;
  }
  .detail-main-info {
    margin-top: 0;
  }
  #detail-tabs nav {
    gap: 12px;
  }
  #detail-tabs nav button {
    font-size: 16px;
    padding: 0 32px;
    min-height: 42px;
  }
  #detail-tabs .products-list {
    gap: 20px;
  }
  .detail-comments-header {
    margin-bottom: 10px;
  }
  .comments-list {
    gap: 32px 24px;
  }
  .under-comment {
    gap: 16px;
  }
  .under-comment .purchase-confirmed {
    white-space: nowrap;
    gap: 4px;
    padding-left: 8px;
    margin-left: auto;
  }
  .under-comment .purchase-confirmed:before {
    font-size: 16px;
  }
  .under-comment .added-date {
    margin-left: 0;
    padding-left: 0;
    border-left: none;
  }
  .comments-stars-wrapper {
    white-space: nowrap;
  }
  .comments-stars-wrapper b {
    font-size: 16px;
  }
  .comments-stars-wrapper i {
    font-size: 14px;
  }
  .detail-ask-button,
  .detail-wishlist {
    padding-left: 28px;
  }
  .detail-ask-button:before,
  .detail-wishlist:before {
    font-size: 18px;
  }
}
@media (max-width: 1100px) {
  .check-person {
    margin-top: 20px;
  }
  .cart-final-boxes {
    display: flex;
    flex-direction: column;
  }
  .checkbox-inline {
    padding: 0 0 15px;
  }
  .checkbox-wrapper {
    padding: 10px;
  }
  .cart-checkbox-main-wrapper .has-many-checkboxes .cart-check-all {
    margin-bottom: 0;
  }
  .cart-checkbox-main-wrapper .has-many-checkboxes .cart-check-all ~ * {
    padding-left: 10px;
  }
  .fields-required-text {
    padding-top: 5px;
  }
  .oauth-signup {
    gap: 10px;
  }
  .oauth-signup > span {
    padding: 20px 0 0;
  }
  .oauth-signup > a.confirm-button {
    font-size: 0;
    padding: 0;
    width: 50px;
    min-width: 0;
  }
  .oauth-signup > a.confirm-button svg {
    margin: 0 !important;
  }
  .goshop-modal {
    padding: 20px 15px;
  }
  .comments-list {
    gap: 30px;
  }
  .comment .author:before {
    display: none;
  }
  .field-wrapper:not(.checking-reg),
  .to-show .field-wrapper:last-of-type {
    margin-bottom: 15px !important;
  }
  #cookies-license {
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: none;
    padding: 10px 10px 10px 80px;
  }
  #cookies-license:before {
    left: 18px;
  }
  .goshop-modal.goshop-modal-form {
    width: 90vw !important;
    max-width: none !important;
  }
  .goshop-modal:not(.goshop-modal-form),
  .modal-form-content {
    padding: 30px 10px !important;
  }
  .cart-success .goshop-modal,
  .availability-modal-wrapper .goshop-modal {
    padding: 0 !important;
  }
  .payment-channels-modal .goshop-modal {
    padding: 0 !important;
  }
  .availability-modal-inner .img-wrapper {
    display: none;
  }
  .order-detailed-info .item-box-small,
  .order-detailed-info .item-box-medium {
    flex-basis: 100%;
    border-right: 0;
  }
  .payment-channels-modal .goshop-modal .wrapper {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .top-bar {
    height: fit-content;
  }
  .top-bar-producers:not(:only-child) {
    display: none;
  }
  .top-bar-texts {
    overflow: initial;
    flex: 1;
  }
  .header-bar {
    gap: 16px;
  }
  .header-bar-right {
    gap: 16px;
  }
  .header-tools-wrapper {
    gap: 16px;
  }
  .main-banner,
  .side-banners-wrapper img {
    border-radius: 4px;
  }
  .main-banner .swiper-slide .banner-text,
  .side-banner .banner-text {
    font-size: 12px;
    bottom: 8px;
  }
  .main-banner .swiper-slide:after,
  .side-banner:after {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 48%, rgba(0, 0, 0, 0.7) 100%);
  }
  .product-price {
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
    justify-content: center;
    line-height: 1.2;
  }
  .blog-banners-wrapper {
    gap: 20px 10px;
  }
  .blog-banners-wrapper .img-wrapper img {
    border-radius: 4px;
  }
  #detail-tabs nav button {
    font-size: 14px;
    padding: 0 14px;
  }
  .comments-list {
    grid-template-columns: 1fr;
  }
  .under-comment {
    gap: 8px;
  }
  #detail-tabs .products-list .product:nth-child(4) {
    display: none;
  }
}
@media (max-width: 1100px) and (max-width: 900px) {
  .header-bar-left > span {
    display: none;
  }
}
@media (max-width: 1100px) and (max-width: 750px) {
  .header-bar-left .phone:nth-child(2) {
    display: none;
  }
}
@media (max-width: 1100px) and (max-width: 900px) {
  .greeting-section {
    flex-direction: column;
    align-items: center;
    aspect-ratio: initial;
    margin-bottom: 12px !important;
  }
  .greeting-section .greeting {
    order: -1;
    width: 100%;
    height: auto;
    text-align: center;
    padding-inline: 5%;
  }
  .greeting-section .greeting .greeting-body {
    overflow: visible;
  }
  .greeting-section:has(.greeting-image-wrapper) .greeting {
    padding-bottom: 68px;
  }
  .greeting-section .greeting-image-wrapper {
    width: 66%;
    height: auto;
  }
  .greeting-section .greeting-image-wrapper figure img {
    border-radius: 8px;
  }
  .greeting-section:has(.greeting) .greeting-image-wrapper {
    position: relative;
    top: -38px;
  }
}
@media (max-width: 1100px) and (max-width: 950px) {
  .blog-banners-wrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (max-width: 600px) {
  .detail-share-links-wrapper span {
    width: 100%;
  }
  .order-buttons-wrapper .wrapper > * {
    flex-grow: 1;
  }
  .order-buttons-wrapper .wrapper > * .return-button {
    width: 100%;
  }
  .cart-promo-code {
    width: 100%;
  }
  .cart-header {
    gap: 10px;
  }
  .cart-header .logo {
    flex-shrink: 1;
    max-width: 40%;
  }
  .cart-header .logo img {
    height: auto;
    width: auto;
    max-height: 40px;
    max-width: 100%;
  }
  .cart-header .cart-back-link {
    white-space: nowrap;
    flex-shrink: 0;
  }
  .check-person {
    gap: 10px;
  }
  form .row-wrapper.address-wrapper,
  form .row-wrapper.city-wrapper {
    grid-template-columns: 1fr;
    gap: 0;
  }
  .stars-rating.average-rating {
    top: -2px;
  }
  .gs-tabs nav {
    flex-direction: column;
  }
  .gs-tabs nav button {
    width: 100%;
  }
  .user-custom-content iframe {
    max-width: 100%;
  }
  .privacy-box {
    padding: 10px;
    gap: 10px;
  }
  .privacy-box__header {
    gap: 10px;
  }
  .privacy-box__header img {
    height: 20px;
  }
  .privacy-box__header strong {
    font-size: 14px;
  }
  .privacy-box__actions {
    flex-wrap: wrap;
  }
  .privacy-box__actions button {
    min-height: 35px;
  }
  .privacy-box__actions button.privacy-accept-all {
    width: 100%;
    order: -1;
    flex: 0 0 auto;
    min-height: 45px;
  }
  .pagination {
    gap: 4px;
  }
  .pagination a {
    width: 30px;
    height: 30px;
  }
  .address-select-list {
    width: calc(100vw - 42px);
    overflow-x: auto;
    flex-wrap: nowrap;
    padding-top: 10px;
  }
  .cart-success .cart-popup-row-wrapper {
    flex-direction: column;
  }
  .tippy-box .tippy-content {
    padding: 10px;
  }
  .infobox {
    padding: 10px;
  }
  .infobox > * {
    width: 100%;
  }
  .infobox:before {
    font-size: 80px;
  }
  #breadcrumb {
    display: flex;
    margin-left: -10px;
    width: calc(100% + 20px);
    justify-content: flex-start;
    position: relative;
  }
  #breadcrumb .breadcrumb-end {
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 20px;
    pointer-events: none;
  }
  #breadcrumb .breadcrumb-end:nth-of-type(1) {
    left: -7px;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff, #ffffff);
  }
  #breadcrumb .breadcrumb-end:nth-of-type(2) {
    right: -7px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff, #ffffff);
  }
  #breadcrumb ul {
    flex-wrap: nowrap;
    overflow-y: auto;
    justify-content: flex-start;
    flex-direction: row-reverse;
    padding-bottom: 5px;
  }
  #breadcrumb li {
    white-space: nowrap;
  }
  #breadcrumb li:last-of-type {
    padding-right: 10px;
  }
  #breadcrumb li:first-of-type {
    padding-left: 10px;
  }
  #breadcrumb li:nth-of-type(1) {
    order: 10;
  }
  #breadcrumb li:nth-of-type(2) {
    order: 9;
  }
  #breadcrumb li:nth-of-type(3) {
    order: 8;
  }
  #breadcrumb li:nth-of-type(4) {
    order: 7;
  }
  #breadcrumb li:nth-of-type(5) {
    order: 6;
  }
  .captcha-wrapper > div {
    width: 268px !important;
    overflow: hidden;
  }
  .captcha-wrapper > div iframe {
    transform-origin: left top;
    transform: scale(0.87);
  }
  .address-select-list li:not(.no-addresses) {
    max-width: 100%;
    min-width: 75%;
  }
  #detail-images .swiper-button-prev {
    left: 0;
  }
  #detail-images .swiper-button-next {
    right: 0;
  }
  #gsGalleryContainer > a {
    width: 100%;
  }
  .navigation-wrapper {
    padding-top: 0;
    border-top: 0;
  }
  .sorter,
  .filter-trigger,
  .sorter-form,
  .sorter-button {
    min-width: 0;
    white-space: nowrap;
  }
  .filter-trigger,
  .sorter {
    width: calc((100% - 15px) / 2);
    margin-bottom: 10px;
  }
  .sorter:before,
  .filter-trigger:before {
    left: 2px;
  }
  .filter-trigger,
  .sorter-button {
    padding: 0 30px 0 10px;
  }
  .filter-trigger svg,
  .sorter-button svg {
    right: 5px;
    transform: translateY(-50%) scale(0.8);
  }
  .filter-trigger:after,
  .sorter-button:after {
    width: 30px;
  }
  .sorter-button {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .sorter-listing {
    left: auto;
    right: 0;
  }
  .goshop-modal header {
    font-size: 16px;
    padding-right: 50px;
    line-height: 1.2;
    height: 50px;
  }
  .goshop-modal header:before {
    width: 40px;
    height: 40px;
  }
  .goshop-modal header ~ .goshop-modal-close,
  .availability-modal-wrapper .goshop-modal-close {
    font-size: 14px;
    width: 50px;
    height: 50px;
  }
  .cart-success section {
    padding: 10px;
    text-align: center;
  }
  .cart-success section figure {
    width: 100%;
    height: 80px;
    margin: 0 0 10px;
  }
  .cart-success section > div {
    width: 100%;
  }
  .cart-success section > div > strong {
    font-size: 16px;
  }
  .cart-success footer {
    padding: 0 10px 10px;
  }
  .cart-success footer,
  .shop-buttons-wrapper {
    gap: 10px;
  }
  .cart-success footer > *,
  .shop-buttons-wrapper > * {
    width: 100%;
    text-align: center;
  }
  .cart-success footer > *:nth-child(2),
  .shop-buttons-wrapper > *:nth-child(2) {
    order: -1;
  }
  .availability-modal-wrapper .wrapper {
    padding: 10px;
    justify-content: center;
    gap: 10px;
  }
  .availability-modal-wrapper .wrapper > p {
    text-align: center;
  }
  .availability-modal-wrapper .wrapper > .availability-product {
    justify-content: center;
  }
  .availability-modal-wrapper .availability-product {
    gap: 10px;
    padding: 10px 0;
  }
  .availability-modal-wrapper .input-wrapper {
    flex-wrap: wrap;
  }
  .availability-modal-wrapper .input-wrapper > * {
    width: 100%;
  }
  .check-person > label {
    width: 100%;
  }
  .check-person .check-simple:first-of-type {
    margin-left: 0;
  }
  .login-form > .signup-wrapper {
    width: 100%;
    padding-right: 0;
    border-right: 0;
  }
  .login-form > .signup-wrapper:nth-child(2) {
    padding-left: 0;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #DFDFDF;
  }
  .cms-subarticles-wrapper {
    columns: 1 !important;
  }
  .user-orders-list .order-header .buttons-wrapper {
    position: static;
    float: left;
    clear: both;
    margin-top: 10px;
    width: 100%;
    flex-direction: row;
  }
  .user-orders-list .order-header .buttons-wrapper a {
    padding: 5px;
  }
  .order-detailed-info .item-box-large th:nth-child(2),
  .order-detailed-info .item-box-large td:nth-child(2) {
    display: none;
  }
  .login-form.logged .logged-header h2 {
    font-size: 18px;
  }
  .product-images {
    width: 100%;
  }
  .product-images .size-full {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }
  .detail-amount {
    width: 100%;
    margin: 0 0 10px;
  }
  .thread-messages .message {
    margin-bottom: 20px;
  }
  .thread-messages .message header {
    flex-wrap: wrap;
    gap: 5px;
  }
  .thread-messages .message header .message-name {
    font-size: 14px;
  }
  .message-time {
    width: 100%;
    margin-top: -5px;
  }
  .thread-messages .message.client-response header .message-time {
    text-align: right;
  }
  .payment-channels-modal .goshop-modal .wrapper {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .pills-group:before,
  .remove-all-filters {
    font-size: 11px;
  }
  .single-pill {
    font-size: 11px;
    padding: 2px 5px;
  }
  #footer .map-wrapper {
    width: 100%;
  }
  .top-bar .top-bar-texts {
    justify-content: center;
    font-size: 11px;
  }
  .top-bar .top-bar-texts span {
    white-space: initial;
  }
  .top-bar .top-bar-texts .poland {
    display: none;
  }
  .top-bar .top-bar-texts .wall {
    width: 100%;
    justify-content: center;
    gap: 5px;
  }
  .top-bar .top-bar-texts .wall:before {
    font-size: 15px;
  }
  .header-bar-left .email {
    display: none;
  }
  .header-location-trigger .header-location-open {
    right: -86px;
  }
  .shop-header-container {
    flex-direction: column;
    height: fit-content;
    padding-block: 3px 11px;
    gap: 2px;
  }
  .shop-header-container .header-tools-wrapper {
    justify-content: space-around;
    width: 100%;
  }
  .header-sentinel {
    position: relative;
    top: 99px;
    left: 0;
  }
  .main-banner {
    grid-column: 1 / 5;
  }
  .side-banner {
    grid-column: span 2;
  }
  .greeting-image-wrapper {
    width: 80% !important;
  }
  .greeting-image-wrapper .decal {
    grid-template-columns: repeat(4, 12px);
    grid-template-rows: repeat(3, 12px);
    bottom: -30px;
    right: -30px;
  }
  #detail-tabs nav {
    gap: 6px;
  }
  .blog-banner .img-wrapper {
    margin-bottom: 10px;
  }
  .under-comment {
    display: grid;
    grid-auto-rows: max-content;
    grid-template-columns: 8fr 2fr;
  }
  .under-comment .stars-rating {
    margin-left: auto;
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }
  .under-comment .purchase-confirmed {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
  }
  #detail-images .swiper-button-prev,
  #detail-images .swiper-button-next {
    top: 50%;
  }
  #detail-info {
    padding: 10px;
  }
  .detail-omnibus {
    flex-wrap: wrap;
    padding-top: 5px;
  }
  .detail-omnibus:before {
    white-space: nowrap;
  }
  #detail-tabs .products-list .product:nth-child(4) {
    display: grid;
    grid-auto-rows: max-content;
  }
  .header-bar:before {
    height: 1px;
  }
  .main-banner .swiper-slide:after,
  .side-banner:after {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 59.5%, rgba(0, 0, 0, 0.7) 100%);
  }
  .detail-comments-header {
    align-items: center;
  }
  .footer-bottom-bar {
    flex-direction: column;
    gap: 16px;
  }
}
@media (max-width: 600px) and (max-width: 510px) {
  .header-bar-left {
    display: none;
  }
  .header-bar-right {
    width: 100%;
    justify-content: space-between;
  }
  .header-location-trigger .header-location-open {
    width: calc(100dvw - 20px);
    right: initial;
    left: 0;
  }
  .header-location-trigger .header-location-open .map-wrapper {
    width: 100%;
  }
}
