// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1100px) {
    // CART
    .check-person {
        margin-top: 20px;
    }

    .cart-final-boxes {
        display: flex;
        flex-direction: column;
    }

    .checkbox-inline {
        padding: 0 0 15px;
    }

    .checkbox-wrapper {
        padding: 10px;
    }

    .cart-checkbox-main-wrapper .has-many-checkboxes .cart-check-all {
        margin-bottom: 0;
    }

    .cart-checkbox-main-wrapper .has-many-checkboxes .cart-check-all ~ * {
        padding-left: 10px;
    }

    .fields-required-text {
        padding-top: 5px;
    }

    .oauth-signup {
        gap: 10px;
    }

    .oauth-signup > span {
        padding: 20px 0 0;
    }

    .oauth-signup > a.confirm-button {
        font-size: 0;
        padding: 0;
        width: 50px;
        min-width: 0;

        svg {
            margin: 0 !important;
        }
    }

    .goshop-modal {
        padding: 20px 15px;
    }

    .comments-list {
        gap: 30px;
    }

    .comment .author:before {
        display: none;
    }

    .field-wrapper:not(.checking-reg),
    .to-show .field-wrapper:last-of-type {
        margin-bottom: 15px !important;
    }

    // OTHER
    #cookies-license {
        bottom: 0;
        right: 0;
        width: 100%;
        max-width: none;
        padding: 10px 10px 10px 80px;

        &:before {
            left: 18px;
        }
    }

    .goshop-modal.goshop-modal-form {
        width: 90vw !important;
        max-width: none !important;
    }

    .goshop-modal:not(.goshop-modal-form),
    .modal-form-content {
        padding: 30px 10px !important;
    }

    .cart-success .goshop-modal, .availability-modal-wrapper .goshop-modal {
        padding: 0 !important;
    }

    .payment-channels-modal .goshop-modal {
        padding: 0 !important;
    }

    .availability-modal-inner {

        .img-wrapper {
            display: none;
        }
    }

    .order-detailed-info .item-box-small,
    .order-detailed-info .item-box-medium {
        flex-basis: 100%;
        border-right: 0;
    }

    .payment-channels-modal .goshop-modal .wrapper {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////
    .top-bar {
        height: fit-content;

        &:has(nth-child(2)) {

        }
    }
    
    .top-bar-producers:not(:only-child) {
        display: none;
    }

    .top-bar-texts {
        overflow: initial;
        flex: 1;
    }

    .header-bar {
        gap: 16px;
    }

    @media (max-width: 900px) {
        .header-bar-left > span {
            display: none;
        }
    }

    @media (max-width: 750px) {
        .header-bar-left .phone:nth-child(2) {
            display: none;
        }
    }

    .header-bar-right {
        gap: 16px;
    }

    .header-tools-wrapper {
        gap: 16px;
    }

    .main-banner,
    .side-banners-wrapper img {
        border-radius: 4px;
    }

    .main-banner .swiper-slide,
    .side-banner {
        .banner-text {
            font-size: 12px;
            bottom: 8px;
        }
    }

    .main-banner .swiper-slide:after,
    .side-banner:after {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 48%, rgba(0, 0, 0, 0.7) 100%);
    }

    @media (max-width: 900px) {
        .greeting-section {
            flex-direction: column;
            align-items: center;
            aspect-ratio: initial;
            margin-bottom: 12px !important;

            .greeting {
                order: -1;
                width: 100%;
                height: auto;
                text-align: center;
                padding-inline: 5%;

                .greeting-body {
                    overflow: visible;
                }
            }

            &:has(.greeting-image-wrapper) {

                .greeting {
                    padding-bottom: 68px;
                }
            }

            .greeting-image-wrapper {
                width: 66%;
                height: auto;

                figure img {
                    border-radius: 8px;
                }
            }

            &:has(.greeting) {

                .greeting-image-wrapper {
                    position: relative;
                    top: -38px;
                }
            }
        }
    }

    .product-price {
        flex-direction: column;
        gap: 0;
        align-items: flex-start;
        justify-content: center;
        line-height: 1.2;
    }

    .blog-banners-wrapper {
        gap: 20px 10px;

        .img-wrapper img {
            border-radius: 4px;
        }
    }

    @media (max-width: 950px) {
        .blog-banners-wrapper {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    #detail-tabs {

        nav {

            button {
                font-size: 14px;
                padding: 0 14px;
            }
        }
    }

    .comments-list {
        grid-template-columns: 1fr;
    }

    .under-comment {
        gap: 8px;

        .purchase-confirmed {
        }
    }

    #detail-tabs .products-list {
        
        .product:nth-child(4) {
            display: none;
        }
    }
}