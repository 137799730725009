// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1680px) and (min-width: 1280px) {
    .inner {
        width: 1170px;
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////
    :root {
        --prodGap: 15px;
        --rowGap: 48px;
    }

    .top-bar .top-bar-texts {
        gap: 16px;
    }

    #search-engine {
        left: initial;
    }

    .header-tools-wrapper {
        gap: 18px;
    }

    .logo {
        position: relative;
        top: -8px;
    }

    #footer .map-wrapper {
        margin-left: 50px;
    }

    .detail-main-wrapper {
        grid-template-columns: 507px minmax(0, 1fr) minmax(0, 313px);
    }

    .detail-headline,
    .detail-additional-infos {
        margin-left: 0;
    }

    #detail-info {
        padding: 16px;
    }

    .detail-amount {
        width: 96px;
    }

    #detail-tabs
    nav button {
        font-size: 16px;
        padding: 0 32px;
    }

    .product-price del {
        top: 0;
    }

    .greeting .greeting-body {
        max-height: 82%;
    }

    .comments-list {
        gap: 32px 16px;
    }

    .under-comment {
        font-size: 15px;
        gap: 8px;

        .added-date {
            padding-left: 8px;
            margin-left: 0;
        }
    }
}